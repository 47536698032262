import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { cached } from '@glimmer/tracking';
import PermissionModel from 'uplisting-frontend/models/permission';
import {
  UserRoles,
  UserOnboardStatus,
  type IUserSchema,
} from 'uplisting-frontend/models/schemas';
import ClientModel from 'uplisting-frontend/models/client';
import CustomerModel from 'uplisting-frontend/models/customer';

export interface IUserJson {
  role: UserRoles;
  userHash: string;
}

export default class UserModel extends Model implements IUserSchema {
  @attr('string') name!: string;
  @attr('string') email!: string;
  @attr('string') role!: UserRoles;
  @attr('string') userHash!: string;
  @attr('boolean') trialist!: boolean;
  @attr('number') unreadMessages!: number;
  @attr('boolean') lapsed!: boolean;
  @attr('number') pendingAlerts!: number;
  @attr('string') gaClientId!: string;
  @attr('array') identities!: string[];
  @attr('string') invitation!: string;
  @attr('string') onboardStatus?: UserOnboardStatus;

  @belongsTo('client', { inverse: 'users', async: false })
  client!: ClientModel;

  @belongsTo('customer', { inverse: 'user', async: false })
  customer?: CustomerModel;

  @hasMany('permission', { inverse: 'user', async: false })
  permissions!: PermissionModel[];

  @cached
  get isAccountOwner(): boolean {
    return this.role === UserRoles.accountOwner;
  }

  @cached
  get isHousekeeper(): boolean {
    return this.role === UserRoles.housekeeper;
  }

  @cached
  get isHost(): boolean {
    return this.role === UserRoles.host;
  }

  @cached
  get isOwner(): boolean {
    return this.role === UserRoles.owner;
  }

  @cached
  get isHostOrAccountOwner(): boolean {
    return this.isHost || this.isAccountOwner;
  }

  @cached
  get isOwnerOrHousekeeper(): boolean {
    return this.isOwner || this.isHousekeeper;
  }

  public hasAccessTo(permission: string): boolean {
    const record = this.permissions.find(
      (item) => item.permission === permission,
    );

    if (!record) {
      return false;
    }

    return record.permitted;
  }

  public toObject(): IUserJson {
    return {
      role: this.role,
      userHash: this.userHash,
    };
  }
}

declare module 'ember-data/types/registries/model' {
  interface ModelRegistry {
    user: UserModel;
  }
}
