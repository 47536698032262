import Controller from '@ember/controller';
import { cached } from '@glimmer/tracking';
import { type Registry as Services, inject as service } from '@ember/service';
import config from 'ember-get-config';

export default class ApplicationController extends Controller {
  @service router!: Services['router'];

  closeAfter = config.notify.closeAfter;

  @cached
  get currentRouteName(): string {
    return this.router.currentRouteName ?? '';
  }

  @cached
  get isRegisterRoute(): boolean {
    return this.currentRouteName.startsWith('register.');
  }
}
