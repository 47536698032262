import EmberRouter from '@ember/routing/router';
import config from 'ember-get-config';
import ApplicationInstance from '@ember/application/instance';
import { type Registry as Services, inject as service } from '@ember/service';

const REPLACE_ROUTES_WITH = {
  '/connect/booking_dot_com': '/connect/booking-dot-com',
  '/connect/price_labs': '/connect/price-labs',
  '/automate/rate_rules': '/automate/rate-rules',
  '/automate/property_attributes': '/automate/property-attributes',
  '/automate/message_tags': '/automate/message-tags',
  '/automate/rental_agreements': '/automate/rental-agreements',
  '/automate/booking_windows': '/automate/booking-windows',
  '/automate/operations/management_fees':
    '/automate/operations/management-fees',
  '/automate/payment_rules': '/automate/payment-rules',
};

const FORBIDDEN_WHEN_LAPSED_ROUTES = [
  '/dashboard',
  '/clients',
  '/insights',
  '/reports',
  '/connect',
  '/notifications',
  '/upsells',
  '/action',
  '/automate',
  '/calendar',
  '/direct',
  '/outbound',
  '/account/notifications',
  '/account/taxes',
  '/account/team',
];

export function isRouteForbiddenWhenLapsed(
  page: string | null | undefined,
): boolean {
  if (!page) {
    return false;
  }

  return FORBIDDEN_WHEN_LAPSED_ROUTES.some((route) => page.startsWith(route));
}

export default class Router extends EmberRouter {
  @service router!: Services['router'];
  @service subscription!: Services['subscription'];

  location = config.locationType;
  rootURL = config.rootURL;

  constructor(instance: ApplicationInstance) {
    super(instance);

    this.on('routeDidChange', () => {
      const page = this.router.currentURL;

      if (isRouteForbiddenWhenLapsed(page)) {
        this.subscription.checkLapsed();
      }

      const replaceRouteWith = REPLACE_ROUTES_WITH[page];

      if (replaceRouteWith) {
        this.router.replaceWith(replaceRouteWith);
      }
    });
  }
}

Router.map(function () {
  this.route('auth-callback');
  this.route('signup');
  this.route('logout');
  this.route('no-access');

  this.route('not-found', { path: '/*path' });
  this.route('notifications', function () {
    this.route('uplisting', function () {
      this.route('pending');
      this.route('resolved');
    });
    this.route('airbnb', function () {
      this.route('host');
      this.route('opportunities');
    });
  });
  this.route('reports', function () {
    this.route('show', { path: '/:id' });
  });
  this.route('proxy');
  this.route('register', function () {
    this.route('info');
    this.route('meeting');
  });

  // default `index` route is not defined for some reason when having `this.route('dashboard');`
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  this.route('dashboard', function () {});
  this.route('clients', function () {
    this.route('statements', function () {
      this.route('deactivate');
      this.route('show', { path: '/:id' });
    });
    this.route('deactivate');
    this.route('management-fee-rules', function () {
      this.route('edit', { path: '/:id' });
    });
    this.route('settings');
    this.route('account-details');
    this.route('client', { path: '/:id' }, function () {
      this.route('profile');
      this.route('properties');
      this.route('access');
      this.route('users');
    });
  });
  this.route('xcalendar', { path: '/xcal' });
  this.route('calendar', function () {
    this.route('starts', { path: '/starts/:id' });
    this.route('inbox');
    this.route('reports');
    this.route('add_property', { path: '/properties/new' });

    this.route('properties', { path: '/properties/:property_id' }, function () {
      this.route('details', function () {
        this.route('address');
        this.route('photos');
      });
      this.route('pricing', function () {
        this.route('base');
      });
    });

    this.route('bookings', { path: '/bookings/:booking_id' }, function () {
      this.route('summary', function () {
        this.route('modify');
        this.route('accept');
      });
      this.route('details');
      this.route('price', function () {
        this.route('edit');
      });
      this.route('messages');
      this.route('identity');
      this.route('logs');
      this.route('replies', function () {
        this.route('index', { path: '/' });
        this.route('new');
        this.route('reply', { path: '/:reply_id' });
      });
    });

    this.route(
      'changeovers',
      { path: '/changeovers/:changeover_id' },
      function () {
        this.route('summary');
        this.route('host');
      },
    );
  });

  this.route('action', { path: '/xaction' }, function () {
    this.route('bookings', { path: '/bookings/:booking_id' }, function () {
      this.route('summary', function () {
        this.route('modify');
        this.route('accept');
      });
      this.route('details');
      this.route('price', function () {
        this.route('edit');
      });
      this.route('messages');
      this.route('identity');
      this.route('logs');
      this.route('replies', function () {
        this.route('index', { path: '/' });
        this.route('new');
        this.route('reply', { path: '/:reply_id' });
      });
    });

    this.route(
      'changeovers',
      { path: '/changeovers/:changeover_id' },
      function () {
        this.route('summary');
        this.route('host');
      },
    );
  });

  this.route('automate', function () {
    this.route('all');
    this.route('markup');
    this.route('payment-rules');
    this.route('payments');
    this.route('deposits');
    this.route('message-tags');
    this.route('property-attributes');
    this.route('enquiries');
    this.route('reviews');
    this.route('cleaning');
    this.route('pricing-sync', { path: 'pricing_sync' });
    this.route('rate-rules');
    this.route('booking-windows');
    this.route('identity');
    this.route('rental-agreements');
    this.route('operations', function () {
      this.route('management-fees');
    });
  });

  this.route('direct', function () {
    this.route('link');
    this.route('content');
    this.route('design');
    this.route('listings');
    this.route('terms');
    this.route('promotions');
  });
  this.route('outbound', function () {
    this.route('messages', function () {
      this.route('new');
    });
  });
  this.route('insights', function () {
    this.route('all');
    this.route('settings');
    this.route('airbnb');
  });

  this.route('account', function () {
    this.route('team');
    this.route('settings');
    this.route('notifications');
    this.route('billing');
    this.route('taxes');
    this.route('lapsed');
    this.route('verify');
  });

  this.route('connect', function () {
    this.route('callback', function () {
      this.route('airbnb');
      this.route('edgestate');
      this.route('stripe');
      this.route('official');
    });

    this.route('wheelhouse');
    this.route('seam', { path: 'smart-locks' });
    this.route('airbnb');
    this.route('edgestate');
    this.route('stripe');
    this.route('zapier');
    this.route('webhook');
    this.route('all');
    this.route('booking-dot-com');
    this.route('homeaway');
    this.route('price-labs');
    this.route('beyond');
    this.route('icals');
    this.route('api');
    this.route('safely');
    this.route('guest-id', { path: 'guestid' });
    this.route('turnoverbnb');
    this.route('minut');
    this.route('protect');
    this.route('pricing');
    this.route('besty');
    this.route('stay-fi');
    this.route('direct', function () {
      this.route('google-analytics');
      this.route('google-ads');
      this.route('facebook-pixel');
      this.route('intercom');
    });
  });

  this.route('upsells', function () {
    this.route('edit', { path: '/:id' });
    this.route('new');
    this.route('orders');
  });
});
