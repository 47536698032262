export default {
  "main-container": "_main-container_1w239r",
  "full-hw": "_full-hw_1w239r",
  "logo": "_logo_1w239r",
  "auth-section": "_auth-section_1w239r",
  "contents": "_contents_1w239r",
  "right-container": "_right-container_1w239r",
  "sidebar": "_sidebar_1w239r",
  "uplisting-logo": "_uplisting-logo_1w239r",
  "sidebar-content": "_sidebar-content_1w239r",
  "section": "_section_1w239r",
  "top-section": "_top-section_1w239r",
  "page-info": "_page-info_1w239r",
  "typography-bodyL": "_typography-bodyL_1w239r",
  "typography-h2": "_typography-h2_1w239r",
  "white": "_white_1w239r",
  "stats-container": "_stats-container_1w239r",
  "stat": "_stat_1w239r",
  "callout-box": "_callout-box_1w239r",
  "author-testimonial": "_author-testimonial_1w239r",
  "author": "_author_1w239r",
  "typography-client": "_typography-client_1w239r",
  "company": "_company_1w239r",
  "font-weight-normal": "_font-weight-normal_1w239r"
};
