import { isNone } from '@ember/utils';
import { getOwner } from '@ember/application';
import type Transition from '@ember/routing/transition';
import ProtectedRoute from 'uplisting-frontend/pods/base/protected-route';
import IndexRoute from 'uplisting-frontend/pods/index/route';
import { UserOnboardStatus } from 'uplisting-frontend/models/schemas';

export default class RegisterMeetingRoute extends ProtectedRoute {
  async beforeModel(transition: Transition): Promise<void> {
    await super.beforeModel(transition);

    const { onboardStatus } = this.currentUser;

    if (isNone(onboardStatus)) {
      await this.router.transitionTo('register.info');
    } else if (onboardStatus === UserOnboardStatus.complete) {
      const indexRoute = getOwner(this)?.lookup('route:index') as IndexRoute;

      await indexRoute.redirectAfterLogin();
    }
  }
}
